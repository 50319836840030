import React from "react";
import {Link} from "gatsby";

import Layout from "../../components/layout";
import {GettingStartedSidebar as Sidebar} from "../../components/sidebar";
import {GettingStartedBreadcrumb as Breadcrumb} from "../../components/breadcrumb";
import {AskHowToInfoAlert, Callout} from "../../components/alert";
import {CodeSnippet} from "../../components/snippet";
import {ApiLink} from "../../components/link";
import {KeyHeader} from "../../components/typography";

const pageTitle = "Bind Input Parameters";

const SqlInsertPositionalAnnotation = () => (
    <>
        This SQL command has three input parameters for
        {' '}<code>NAME</code>, {' '}<code>AGE</code>{' '} and {' '}<code>COMMENTS</code>{' '} columns.
        Input parameters are marked by
        the {' '}<code>:1</code>, {' '}<code>:2</code>, {' '}<code>:3</code>{' '} markers.
        This means we need to bind parameters by their positions:
    </>
);

const SqlInsertNamedAnnotation = () => (
    <>
        This SQL command has three input parameters for
        {' '}<code>NAME</code>, {' '}<code>AGE</code>{' '} and
        {' '}<code>COMMENTS</code>{' '} columns. Input parameters are marked
        by {' '}<code>:name</code>, {' '}<code>:age</code>{' '} and {' '}<code>:comments</code>{' '} markers.
        It means we need to bind parameters by their names:
    </>
);

const CreateCommandWithParamsAnnotation = () => (
    <>
        <div className="mb-1">
            First statement creates a command object {' '}<code>cmd</code>{' '} (for more details see
            {' '}<Link to="/HowTo/commands/">Execute SQL command</Link>{' '} and
            {' '}<Link to="/HowTo/procedures/">Call stored procedure</Link>).
        </div>
        <div>
            After creating the {' '}<code>cmd</code>{' '} object and passing a command text to it, three
            {' '}<ApiLink>SAParam</ApiLink>{' '} objects are created automatically.
        </div>
    </>
);

const Page = () => (
    <Layout pageTitle={pageTitle}
            sidebarNav={<Sidebar/>}
            mobileNav={<Breadcrumb activeName={pageTitle}/>}
    >
        <p>
            You should assign values for input variables any time you want
            to execute an SQL command or a stored procedure with input parameters.
        </p>
        <p>
            In this tutorial we will be inserting rows into the following table:
        </p>
        <CodeSnippet language="sql">
            CREATE TABLE EMPLOYEES(NAME CHAR(25), AGE INTEGER, COMMENTS CHAR(25))
        </CodeSnippet>

        <p>
            The library provides two alternative methods for binding input parameters.
            Both methods yield identical results, so it is just a matter of preference which one you prefer:
        </p>
        <ul>
            <li>
                <a href="#assigning_value_with_SAParam">assigning a value to SAParam object</a>
            </li>
            <li>
                <a href="#using_stream_operator">{'using stream operator <<'}</a>
            </li>
        </ul>

        <Callout className="mb-5" heading="Binding Long/CLob/BLob">
            Binding Long, BLob and CLob data can have some differences
            from binding other data types &ndash; for example, when using piecewise capabilities. See
            {' '}<Link className="alert-link" to="/HowTo/blobs/">Handle Long/CLob/BLob</Link>{' '}
            for additional information.
        </Callout>

        <KeyHeader id="assigning_value_with_SAParam">
            Assigning a Value with SAParam Object
        </KeyHeader>
        <p>
            {' '}<ApiLink>SAParam</ApiLink>{' '} object
            represents a parameter object associated with a command.
        </p>

        <h5>Example 1 &ndash; Positional Parameters</h5>
        <p>
            Consider the following SQL command to insert a row into the {' '}<code>EMPLOYEES</code>{' '} table:
        </p>
        <CodeSnippet language='sql'>
            INSERT INTO EMPLOYEES (NAME, AGE, COMMENTS) VALUES (:1, :2, :3)
        </CodeSnippet>

        <p>
            <SqlInsertPositionalAnnotation/>
        </p>
        <CodeSnippet>{`
SACommand cmd(
    &Connection, 
    _TSA("INSERT INTO EMPLOYEES (NAME, AGE, COMMENTS) VALUES (:1, :2, :3)"));
    
cmd.Param(1).setAsString() = _TSA("Roy Mann");
cmd.Param(2).setAsInt32() = 42;
cmd.Param(3).setAsNull();

cmd.Execute();
                    `.trim()}
        </CodeSnippet>

        <div className="mb-2">The code above performs the following simple steps:</div>
        <ul className="mb-4">
            <li className="mb-2">
                <CreateCommandWithParamsAnnotation/>
            </li>
            <li className="mb-2">
                <div className="mb-1">
                    Next group of three statements binds input variables by assigning values to
                    {' '}<ApiLink>SAParam</ApiLink>{' '} objects using positional overload of the
                    {' '}<ApiLink>SACommand::Param</ApiLink>{' '} method.
                </div>
                <div>
                    The code sets the value of {' '}<code>"Roy Mann"</code>{' '} to the parameter
                    marked by {' '}<code>:1</code>,{' '} {' '}<code>42</code>{' '} to the parameter marked by
                    {' '}<code>:2</code>{' '} and {' '}<code>null</code>{' '} value to the parameter marked by
                    {' '}<code>:3</code>.
                </div>
            </li>
            <li>
                Last statement executes the command with bound parameters and sends it to the server.
            </li>
        </ul>

        <h5>Example 2 &ndash; Named Parameters</h5>
        <p>
            We can use another type of command text parameter binding to insert a row into the table:
        </p>
        <CodeSnippet language="sql">
            INSERT INTO EMPLOYEES (NAME, AGE, COMMENTS) VALUES (:name, :age, :comments)
        </CodeSnippet>

        <p>
            <SqlInsertNamedAnnotation/>
        </p>
        <CodeSnippet>{`
SACommand cmd(
    &Connection, 
    _TSA("INSERT INTO EMPLOYEES (NAME, AGE, COMMENTS) VALUES (:name, :age, :comments)"));
    
cmd.Param(_TSA("name")).setAsString() = _TSA("Roy Mann");
cmd.Param(_TSA("age")).setAsInt32() = 42;
cmd.Param(_TSA("comments")).setAsString() = _TSA("Manager");

cmd.Execute();    
                    `.trim()}
        </CodeSnippet>

        <div className="mb-5">
            The code above is identical to the one in the previous example, except that it uses named marker bindings.
            It sets the value of {' '}<code>"Roy Mann"</code>{' '} to the parameter
            marked by {' '}<code>:name</code>, {' '}<code>42</code>{' '} to the parameter marked by
            {' '}<code>:age</code>{' '} and {' '}<code>"Manager"</code>{' '} to the parameter marked by
            {' '}<code>:comments</code>.
        </div>

        <KeyHeader id="using_stream_operator">{`Using the stream operator <<`}</KeyHeader>
        <p>
            {' '}<ApiLink>{`SACommand::operator <<`}</ApiLink>{' '}
            is a stream operator, so usually it is more convenient to use it
            compared to assigning {' '}<ApiLink>SAParam</ApiLink>{' '} objects.
        </p>

        <h5 id="example_3">Example 3 &ndash; Positional Parameters</h5>
        <p>
            Consider once more the following SQL command:
        </p>
        <CodeSnippet language="sql">
            INSERT INTO EMPLOYEES (NAME, AGE, COMMENTS) VALUES (:1, :2, :3)
        </CodeSnippet>

        <p>
            <SqlInsertPositionalAnnotation/>
        </p>
        <CodeSnippet>{`
SACommand cmd(
    &Connection,
    _TSA("INSERT INTO EMPLOYEES (NAME, AGE, COMMENTS) values (:1, :2, :3)"));
    
cmd << _TSA("Roy Mann") << 42 << SANull();

cmd.Execute();
                    `.trim()}
        </CodeSnippet>

        <div className="mb-2">The code above performs the following simple steps:</div>

        <ul className="mb-4">
            <li className="mb-2">
                <CreateCommandWithParamsAnnotation/>
            </li>
            <li className="mb-2">
                <div className="mb-1">
                    Next statement binds input variables using
                    {' '}<ApiLink>{`SACommand::operator <<`}</ApiLink>.
                </div>
                <div className="mb-1">
                    The code sets the value of {' '}<code>"Roy Mann"</code>{' '} to the
                    parameter marked by {' '}<code>:1</code>, {' '}<code>42</code>{' '} to the parameter
                    marked by {' '}<code>:2</code>{' '} and {' '}<code>null</code>{' '} value to the parameter marked
                    by {' '}<code>:3</code>.
                </div>
                <div>
                    The order of the values is important &ndash;
                    the first {' '}<code>{`<<`}</code>{' '} binds to {' '}<code>:1</code>, the second
                    {' '}<code>{`<<`}</code>{' '} to {' '}<code>:2</code>{' '}
                    and the third {' '}<code>{`<<`}</code>{' '} to {' '}<code>:3</code>.
                </div>
            </li>
            <li>
                Last statement executes the command with bound parameters and sends it to the server.
            </li>
        </ul>

        <h5>Example 4 - Named Parameters</h5>
        <p>
            We can use another type of command text parameter binding to insert a row into the table:
        </p>
        <CodeSnippet language="sql">
            INSERT INTO EMPLOYEES (NAME, AGE, COMMENTS) VALUES (:name, :age, :comments)
        </CodeSnippet>

        <p>
            <SqlInsertNamedAnnotation/>
        </p>
        <CodeSnippet>{`
SACommand cmd(
    &Connection, 
    _TSA("INSERT INTO EMPLOYEES (NAME, AGE, COMMENTS) VALUES (:name, :age, :comments)"));
    
cmd << SAPos(_TSA("name")) << _TSA("Roy Mann") 
    << SAPos(_TSA("age")) << 42
    << SAPos(_TSA("comments")) << _TSA("Manager");

cmd.Execute();
                    `.trim()}
        </CodeSnippet>

        <div className="mb-1">
            Because of binding parameters by their names we need to use more
            complex sequence of stream elements. In {' '}<a href="#example_3">Example 3</a>{' '} we
            put parameter values into the stream in order of their numerical positions.
        </div>
        <div className="mb-5">
            Here we need to explicitly specify the named positions of bound
            variables before putting values into the stream. To specify a position
            we used {' '}<ApiLink>SAPos</ApiLink>{' '} objects.
        </div>

        <AskHowToInfoAlert/>
    </Layout>
);

export default Page;
